.contact-form-container {
  position: relative;
  background-image: url("../../assets/images/banner-img-2.jpg"); /* Path to your background image */
  background-size: cover;
  background-position: center;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-contact {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay */
  z-index: 1;
}

.contact-form-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form-content h2 {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.contact-form-content h1 {
  font-family: "Dosis", sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  flex: 1;
  padding: 15px;
  font-size: 16px;
  border: 1px solid white;
  background: transparent;
  color: white;
  font-family: "Dosis", sans-serif;
  border-radius: 4px;
  outline: none;
}

.form-group textarea {
  height: 150px;
  resize: none;
}

button {
  padding: 15px 30px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: white;
  color: black;
}

.contact-title {
  font-family: "Dosis", sans-serif;
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 30px;
}

.contact-subtitle {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .contact-title {
    font-size: 32px;
  }

  .contact-subtitle {
    font-size: 10px;
    line-height: 1em;
    letter-spacing: 2px;
  }

  .form-group {
    flex-direction: column;
  }
}
