.testimonial-slider {
  padding: 50px 20px;
  text-align: center;
  margin: 0 auto;
}

.slider-title {
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  color: #6c6c6c;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.slider-subtitle {
  font-family: "Dosis", sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.testimonial-quote {
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  color: #6c6c6c;
  margin-bottom: 20px;
}

.testimonial-name {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.testimonial-title {
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  color: #6c6c6c;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.swiper-pagination-bullet {
  background-color: #ccc;
  width: 10px;
  height: 10px;
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background-color: #b8b8b8 !important;
}

.swiper {
  padding: 50px !important;
  width: 55% !important;
}

@media screen and (max-width: 768px) {
  .swiper {
    padding: 30px 0 !important;
    width: 100% !important;
  }
}
