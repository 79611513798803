.home-page-banner {
  width: 100%;
  height: 100vh;
  background-color: green;
  background-image: url("../assets/images/banner-img.jpeg");
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
}

.veil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  width: 50%;
  height: 60%;
}

.banner-title {
  font-family: "Dosis", sans-serif;
  color: rgb(119, 119, 119);
  font-weight: 500;
  font-size: 30px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 15px;
  line-height: 30px;
  width: 100%;
}

.animated-text {
  font-size: 50px;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 8px;
  line-height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  color: white;
  flex-direction: column;
}

.letter {
  display: inline-block;
  min-width: 15px;
}
.letter-space {
  display: inline-block;
  width: 20px;
}

.banner-text {
  font-family: "Dosis", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 25px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 15px;
  line-height: 30px;
  margin-bottom: 20px;
}

.flip-class {
  display: flex;
  flex-direction: row;
}

.flip-class-left {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  width: 100%;
}

.red {
  color: rgb(255, 204, 2);
}

.banner-buttons {
  margin-top: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.logo-container {
  margin-top: 100px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: calc(100% - 2px);
    width: 100%;
    height: 4px;
    background-color: rgb(255, 204, 2);
    transform-origin: center center;
    transform: scaleX(0);

    animation: line-animation 3s ease forwards;
    animation-delay: 4s;
  }

  #page-logo {
    font-family: "Dosis", sans-serif;
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 10px;
    color: white;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    animation: clip-path-reveal-1 3s ease forwards;
    animation-delay: 4.25s;
  }
}

@keyframes line-animation {
  0% {
    transform: scaleX(0);
  }
  15% {
    transform: scaleX(0);
  }
  20%,
  25% {
    transform: scaleX(1);
    top: calc(100% - 2px);
  }
  50% {
    transform: scaleX(1);
    top: 0px;
  }
  70% {
    transform: scaleX(1);
    top: 0px;
  }
  80%,
  100% {
    transform: scaleX(1);
    top: 0px;
  }
}

@keyframes clip-path-reveal-1 {
  0%,
  25% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.about-us {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.logo-container-about-us {
  position: relative;
  margin-top: 2px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 95%;
    height: 3px;
    background-color: rgb(255, 204, 2);
  }

  #page-logo-about-us {
    font-family: "Dosis", sans-serif;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 10px;
    font-weight: 600;
    color: black;
  }
}

.about-us-title {
  font-family: "Dosis", sans-serif;
  color: black;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 8px;
  line-height: 60px;
}

.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 100px 150px;
}
.about-us-content {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 3px;
  line-height: 35px;
  text-align: center;
}

.black {
  color: rgb(255, 204, 2);
}

@media screen and (max-width: 1280px) {

  .logo-container-about-us {
    margin-top: 5px;

    &:before {
      height: 3px;
    }

    #page-logo-about-us {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 5px;
    }
  }

  .about-us-title {
    font-size: 40px;
    letter-spacing: 5px;
    line-height: 50px;
  }

  .about-us-content {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 30px;
  }
}

@media screen and (max-width: 960px) {
  .animated-text {
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 5px;
    line-height: 50px;
  }

  .banner-title {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 10px;
    line-height: 20px;
  }

  .logo-container-about-us {
    margin-top: 1px;

    &:before {
      height: 3px;
    }

    #page-logo-about-us {
      font-size: 35px;
      line-height: 40px;
      letter-spacing: 3px;
    }
  }

  .about-us-title {
    font-size: 35px;
    letter-spacing: 3px;
    line-height: 40px;
  }

  .about-us-content {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 25px;
  }
}

@media screen and (max-width: 768px) {
  .animated-text {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 40px;
  }

  .banner-content {
    width: 70%;
    height: 75%;
  }

  .banner-title {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 5px;
    line-height: 10px;
  }

  .logo-container {
    margin-top: 50px;

    &:before {
      content: "";
      position: absolute;
      top: calc(100% - 2px);
      width: 100%;
      height: 3px;
    }

    #page-logo {
      font-size: 50px;
      line-height: 55px;
      letter-spacing: 5px;
      color: white;
    }
  }

  .banner-buttons {
    margin-top: 50px;
    flex-direction: column-reverse;
    gap: 50px;
  }

  .logo-container-about-us {
    margin-top: 1px;

    &:before {
      height: 3px;
    }

    #page-logo-about-us {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 2px;
    }
  }

  .about-us-title {
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 30px;
  }

  .about-us-content {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 20px;
  }

  .about-us-container {
    gap: 50px;
    padding: 25px;
  }
}
