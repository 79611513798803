.product-gallery-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 300px;
}

.product-gallery-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.second-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: -100%; /* Start the overlay completely off-screen */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: left 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
}

.product-gallery-item:hover .overlay {
  left: 0; /* Slide the overlay in from the left */
}

.product-gallery-item:hover .product-image {
  transform: scale(1.05); /* Optional: slightly zoom the image on hover */
}

.overlay-content h3 {
  font-family: "Dosis", sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

.overlay-content p {
  font-family: "Dosis", sans-serif;
  font-size: 16px;
}

.gallery-item-title {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 1.5px;
  line-height: 35px;
  text-align: center;
  margin-top: 25px;
}
.gallery-item-subtitle {
  margin-top: 10px;
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #6c6c6c;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media screen and (max-width: 768px) {
  .product-gallery-item-container {
    border: 1px solid black;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
