.work-process-step {
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(33.333% - 20px); /* 3 items per row with spacing */
  margin-bottom: 40px; /* Add some space between rows */
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.icon-container {
  width: 100px;
  height: 100px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
}

.icon-container.hovered {
  background-color: #f1f1f1; /* Change background color on hover */
  .icon {
    fill: black;
  }
}

.icon {
  max-width: 50%;
  max-height: 50%;
  fill: #f1f1f1;
  transition: all 0.3s ease;
}

.label {
  margin-top: 10px;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #6c6c6c;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Tooltip styling */
.tooltip {
  position: absolute;
  top: -60px; /* Position the tooltip above the icon */
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.4;
  z-index: 10;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  visibility: hidden;
}

.work-process-step:hover .tooltip {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .icon-container {
    width: 50px;
    height: 50px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    position: relative;
  }

  .label {
    margin-top: 10px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
}
