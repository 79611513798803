.image-text-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  background-color: rgb(242, 242, 242);
  height: 500px;
  margin-bottom: 25px;
}

.image-text-block.reverse {
  flex-direction: row-reverse; /* Reverses the order of image and text */
}

.image {
  /* Make the image take up the full width of its container */
  height: 100%; /* Make the image take up the full height of its container */
  object-fit: cover; /* Ensures the image covers the container without distorting */
  border-radius: 8px;
  width: 50%;
  background-color: green;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 25px;
}

.title {
  font-family: "Dosis", sans-serif;
  flex: 1;
  font-weight: 400;
  font-size: 32px;
  color: #303030;
  line-height: 1.3em;
  letter-spacing: 10px;
  text-transform: uppercase;
  text-align: center;
}

.text {
  font-family: "Dosis", sans-serif;
  flex: 1;
  font-weight: normal;
  font-size: 16px;
  color: #595959;
  text-align: center;
  width: 75%;
}

@media screen and (max-width: 960px) {
  .title {
    font-size: 28px;
    line-height: 1em;
    letter-spacing: 6px;
  }
}
@media screen and (max-width: 768px) {
  .image-text-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    height: auto;
    flex-direction: column;
    margin: 25px;
    border-radius: 8px;
  }

  .image-text-block.reverse {
    flex-direction: column;
  }

  .image {
    width: 100%;
  }

  .text-content {
    width: 100%;
  }

  .title {
    font-size: 24px;
    line-height: auto;
    letter-spacing: 4px;
  }

  .text {
  font-family: "Dosis", sans-serif;
  flex: 1;
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  text-align: center;
  width: 75%;
}
}
