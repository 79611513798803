.footer {
  background-color: white;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-title {
  font-family: "Dosis", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-social {
}

.footer-social a {
  color: #333;
  margin: 0 10px;
  font-size: 24px;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #000;
}

.footer-note {
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  color: #6c6c6c;
}

.logo-container-footer {
  position: relative;
  width: fit-content;
  margin-bottom: 15px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 95%;
    height: 2px;
    background-color: rgb(255, 204, 2);
  }

  #page-logo-footer {
    font-family: "Dosis", sans-serif;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 10px;
  }
}

@media screen and (max-width: 768px) {
  .footer-social a {
    font-size: 18px;
  }
}
