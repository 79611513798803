.work-process {
  text-align: center;
  padding: 50px 0;
}

.work-process-title {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #303030;
  line-height: 1.3em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.work-process-subtitle {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: #303030;
  line-height: 1.3em;
  letter-spacing: 10px;
  text-transform: uppercase;
  margin: 20px 0 75px 0;
}

.steps-container {
  display: flex;
  justify-content: space-between; /* Space between items */
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .work-process-subtitle {
    font-size: 28px;
    line-height: 1em;
    letter-spacing: 2px;
    margin: 10px 0 75px 0;
  }

  .work-process-title {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .work-process {
    text-align: center;
    padding: 10px 0;
  }

  .steps-container {
    display: flex;
    justify-content: space-around; /* Space between items */
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
}
