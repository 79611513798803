.map-component {
  background-image: url("../../assets/images/banner-img-2.jpg");
  color: #fff; /* White text color */
  padding: 40px 20px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.map-header {
  margin-bottom: 30px;
      z-index: 100;
    position: relative;
}

.overlay-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay */
  z-index: 1;
}

.map-title {
  font-family: "Dosis", sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.map-description {
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  color: #ccc; /* Lighter color for the description text */
  margin-bottom: 20px;
}

.map-container {
  width: 100%;
  height: 400px;
  border: none;
  z-index: 10;
  position: relative;
}

.map-iframe {
  width: 100%;
  height: 100%;
  border: 0; /* Remove default iframe border */
  border-radius: 8px;
  z-index: 1000;
}
