.product-gallery {
  padding: 50px 20px;
  text-align: center;
}

.gallery-title {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: #303030;
  line-height: 1.3em;
  letter-spacing: 10px;
  text-transform: uppercase;
  margin: 20px 0;
}

.gallery-subtitle {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #303030;
  line-height: 1.3em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 75px 20px;
  justify-items: center;
  align-items: center;
}

@media (max-width: 900px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .product-gallery {
    padding: 0px 20px;
    margin-bottom: 50px;
  }

  .gallery-title {
    font-size: 28px;
    line-height: 1em;
    letter-spacing: 5px;
    margin: 10px 0;
  }
}
