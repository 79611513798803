header {
  position: fixed;
  width: 100vw;
  height: 50px;
  top: 0;
  left: 0;
  padding: 25px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0); /* Transparent */
  transition: background-color 0.5s ease; /* Smooth transition */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 10px;
}

header.scrolled {
  background-color: rgba(0, 0, 0, 0.85) !important; /* Black with opacity */
}

.logo {
  font-family: "Dosis", sans-serif;
  font-weight: normal !important;
  font-size: 11px;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.nav-container {
  display: flex;
  align-items: center;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 50px;
}

nav ul li a {
  font-family: "Dosis", sans-serif;
  font-weight: normal !important;
  font-size: 12px;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-decoration: none;
  cursor: pointer;
}

.logo-container-header {
  position: relative;
}

#page-logo-header {
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 10px;
  color: white;
}

/* Burger menu styling */
.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px;
  transition: all 0.3s ease;
}

.burger.open .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.burger.open .line2 {
  opacity: 0;
}

.burger.open .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Responsive Styles */
@media screen and (max-width: 960px) {
nav ul {

  gap: 25px;
}
}
@media screen and (max-width: 768px) {
  nav ul {
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 34px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 25%;
    padding-inline-start: 0;
  }

  .nav-container.open nav ul {
    transform: translateX(0%);
  }

  nav ul li {
    margin: 20px 0;
    text-align: center;
  }

  .burger {
    display: block;
  }

  .logo-container-header {
    margin-right: auto;
  }
  #page-logo-header {
  font-size: 16px;
  line-height: 10px;
  letter-spacing: 5px;
}
}
