.button-style {
  padding: 0 25px;
  border: none;
  cursor: pointer;
  font-family: "Dosis", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 3px;
  line-height: 35px;
  white-space: nowrap;
  overflow: visible;
  min-height: 0px;
  width: auto;
  height: auto;
  border-style: solid;
  border-width: 1px;
  z-index: 1000;
  border-radius: 0px;
  transition: all 0.3s ease;
  min-width: 200px;
}

.button-style:hover {
  background-color: transparent !important;
  color: white !important;
  border-color: white !important;
}
